import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";

export function useHeaderImage() {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "headers/grocery-table-anna.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);

  return {
    image,
    // credit: "Anna Seccombe for Just Kai",
    // url: "",
  };
}
