import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import {
  Text,
  Heading,
  Grid,
  GridItem,
} from "@chakra-ui/react";

import Link from "./Link";
import Tags from "./Tags";

export default function Post({node, showTags}) {
  return (
    <Grid 
      key={node.id} 
      as="article"
      gap="0" 
      templateColumns={["1fr", null, "48fr 52fr"]}
      templateAreas={[
        `"image" 
         "text" 
         "tags"`, 
        null, 
        `"text image" 
         "text tags"`, 
        `"text image" 
         "tags tags"`,
         null, 
        `"text image" 
         "tags image"`]}
      textStyle="plainLinks"
      maxW="1292px" // driven by max image size
    >
      <GridItem pr={[0, null, "2rem", null, "80px"]} pt={["1rem", null, 0]} area="text">
        <Heading as="h2" fontSize="1.75rem">
          <Link href={`/${node.slug}`}>
            {node.frontmatter.title}
          </Link>
        </Heading>
        <Text mb="2rem" fontSize="md">{node.frontmatter.date.toUpperCase()}</Text>
        <Text mb="1rem">
          {node.frontmatter.description}
        </Text>
        <Link href={`/${node.slug}`}>
          <Text as="em" color="brand.lightBlue" fontSize="xl">Read More</Text>
        </Link>
      </GridItem>
      <GridItem area="tags">
        {showTags && node.frontmatter.tags && (
          <Tags mode="post" pt={["1rem", null, null, null]}>
            {node.frontmatter.tags}
          </Tags>
        )}
      </GridItem>
      <GridItem area="image" >
        <Link href={`/${node.slug}`} >
          <GatsbyImage
            width={["100%", null, "50%"]}
            image={getImage(node.frontmatter.image)}
            alt="" // these should all be non-informational images
          />
        </Link>
      </GridItem>
    </Grid>
  )
}