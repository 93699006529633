import React from "react";
import { VStack } from "@chakra-ui/react";

import Post from "./Post";

export default function BlogRoll({ edges, filterTag }) {
  const posts = edges.map((e) => {
    if (
      filterTag &&
      e.node.frontmatter.tags &&
      !e.node.frontmatter.tags.map((t) => t.toLowerCase()).includes(filterTag)
    ) {
      return <></>;
    }
    return <Post node={e.node} showTags={true} />;
  });

  return <VStack spacing="75px">{posts}</VStack>;
}
