import React from "react";

import {
  Tag,
  TagLabel,
  Flex,
} from "@chakra-ui/react";

import Link from "./Link";

const Tags = ({mode, children, ...args}) => (
  <Flex 
    direction="row" 
    wrap="wrap" 
    columnGap={2} 
    rowGap={2} 
    color="brand.light"
    {...args} 
    {...{justifyContent: mode==="header" ? "center" : ""}} 
  >
    {children.map((tag, i) => (
      <div key={i}>
        <Link href={`/blog/?tag=${tag.toLowerCase()}`}>
          <Tag size="md" borderRadius="full" bg={mode==="header" ? "brand.darkBlue" : "brand.green"}>
            <TagLabel color="brand.light" fontFamily="heading" fontSize="md">{tag.toUpperCase()}</TagLabel>
          </Tag>
        </Link>
      </div>
    ))}
  </Flex>
)

export default Tags