import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useQueryParam } from "gatsby-query-params";

import { Heading, Text } from "@chakra-ui/react";

import BlogRoll from "../components/BlogRoll";
import Framed from "../components/Framed";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { SpecificHeaders } from "../components/SocialHeaders";
import { useHeaderImage } from "../components/images/manyGroceriesHeader";

export default function BlogPage({ location }) {
  const data = useStaticQuery(
    graphql`
      query BlogRollQuery {
        allMdx(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: {
            slug: { regex: "/^blog/" }
            frontmatter: { draft: { eq: false } }
          }
        ) {
          edges {
            node {
              id
              slug
              frontmatter {
                title
                description
                tags
                date(formatString: "MMMM DD, YYYY")
                image {
                  childImageSharp {
                    gatsbyImageData(width: 672, height: 336)
                  }
                }
              }
            }
          }
        }
      }
    `
  );

  const filterTag = useQueryParam("tag", null);

  return (
    <>
      <Header imageHook={useHeaderImage}>
        <SpecificHeaders
          title={filterTag ? `${filterTag} Posts` : "Blog"}
          path={filterTag ? `/blog/?tag=${filterTag}` : "/blog/"}
        />
        {filterTag ? (
          <Heading>
            Posts tagged{" "}
            <Text as="span" sx={{ fontVariant: "small-caps" }}>
              {filterTag}
            </Text>
          </Heading>
        ) : (
          <Heading>Latest Posts</Heading>
        )}
      </Header>
      <Framed as="main" id="main" py={["2rem", null, null, "90px"]}>
        <BlogRoll filterTag={filterTag} edges={data.allMdx.edges} />
      </Framed>
      <Footer />
    </>
  );
}
